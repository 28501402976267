<template>
  <div>
    <v-card outlined>
      <v-card-title :class="$classes.cardTitle">
        <span class="font-weight-bold title mr-5 mb-3 d-flex align-center"
          ><v-icon class="mr-1">mdi-tab-plus</v-icon>Mealplan Requests</span
        >
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-5 pt-5 pb-0 pr-0 fill-height">
        <v-row>
          <v-col class="fill-heigth">
            <v-card flat class="overflow-y-hidden overflow-x-hidden pb-5">
              <v-row class="my-3 mx-2 justify-center gap-10">
                <v-btn
                  v-for="(stat, i) in [
                    {
                      stat: 'requesting',
                      text: 'Requesting',
                      count: requested,
                    },
                    { stat: 'declined', text: 'Declined', count: rejected },
                    { stat: 'success', text: 'Success', count: approved },
                  ]"
                  :color="view == stat.stat ? 'primary' : 'grey'"
                  class="mr-2 text-none text-capitalize"
                  :outlined="view !== stat.stat"
                  @click="setView(stat.stat)"
                  depressed
                  :key="i"
                  small
                >
                  <span>{{ stat.text }}</span>
                  <v-badge
                    v-if="
                      stat.count &&
                      stat.count.length &&
                      stat.stat == 'requesting'
                    "
                    color="red"
                    small
                    right
                    offset-x="-3"
                    offset-y="-3"
                    :content="stat.count.length"
                  ></v-badge>
                </v-btn>
              </v-row>
              <preloader v-if="status.getting" />
              <template v-if="!status.getting && confPlans.length">
                <div
                  class="d-flex justify-start flex-wrap gap-15"
                  style="width: 100%"
                >
                  <v-card
                    max-width="240"
                    width="100%"
                    outlined
                    hover
                    v-for="plan in confPlans"
                    :key="plan.id"
                  >
                    <v-card-text>
                      <h2 class="primary--text">{{ plan.name }}</h2>
                      <div class="caption">
                        {{ getUser(plan.user) }}
                      </div>
                      <div class="caption">
                        {{ getUserRole(plan.user) }}
                      </div>
                    </v-card-text>
                    <v-card-actions class="px-3">
                      <v-btn
                        @click="openViewPlanDialog(plan)"
                        depressed
                        small
                        class="info"
                        block
                      >
                        View
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </div>
              </template>

              <template v-if="!status.getting && !confPlans.length">
                <v-alert type="info" border="left" class="mb-0" dense>
                  No plans found
                </v-alert>
              </template>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="viewDialog" width="1200" persistent>
      <close-button @click="closeViewPlanDialog" overflow />
      <v-card>
        <v-card-text
          class="py-3"
          v-if="viewPlan?.requestStatus == 'requesting'"
        >
          <p class="text-center">
            Accept or Decline the Meal Plan of
            <strong
              >{{ getUser(viewPlan.user) }} ({{
                getUserRole(viewPlan.user)
              }}) - <a :href="`mailto:${getEmail(viewPlan.user)}`">{{getEmail(viewPlan.user)}}</a></strong
            >
          </p>
          <div class="d-flex flex-wrap justify-center gap-5 text-center">
            <v-btn
              @click="approveMealplan(viewPlan)"
              :loading="requesting"
              depressed
              small
              class="primary"
              v-if="viewPlan?.requestStatus == 'requesting'"
            >
              Accept
            </v-btn>
            <v-btn @click="rejectDialog = true" depressed small class="error">
              Decline
            </v-btn>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-text>
          <div
            class="pa-5"
            v-if="viewPlan"
            style="overflow: auto; max-height: 720px"
          >
            <v-row justify="center pb-5">
              <v-col cols="9">
                <div class="title">Client Info:</div>
              </v-col>
              <v-col cols="12">
                <v-row justify="center text-left">
                  <v-col cols="12" lg="4" class="pa-0">
                    <div class="subtitle-1">
                      <span class="font-weight-bold">Name: </span>
                      {{ goal?.client }}
                    </div>
                  </v-col>
                  <v-col cols="12" lg="4" class="pa-0">
                    <div class="subtitle-1">
                      <span class="font-weight-bold">Client Email: </span>
                      {{ goal?.email }}
                    </div>
                  </v-col>
                </v-row>
                <v-row justify="center text-left">
                  <v-col cols="12" lg="4" class="pa-0">
                    <div class="subtitle-1">
                      <span class="font-weight-bold">Age: </span>
                      {{ goal?.age }}
                    </div>
                  </v-col>
                  <v-col cols="12" lg="4" class="pa-0">
                    <div class="subtitle-1">
                      <span class="font-weight-bold">Gender: </span>
                      {{ goal?.gender }}
                    </div>
                  </v-col>
                </v-row>
                <v-row justify="center text-left">
                  <v-col cols="12" lg="4" class="pa-0">
                    <div class="subtitle-1">
                      <span class="font-weight-bold">Primary Goal: </span>
                      {{ getGoalValue(goal?.goal).text }}
                    </div>
                  </v-col>
                  <v-col cols="12" lg="4" class="pa-0">
                    <div class="subtitle-1">
                      <span class="font-weight-bold">Height: </span>
                      {{ goal?.height }}
                    </div>
                  </v-col>
                </v-row>
                <v-row justify="center text-left">
                  <v-col cols="12" lg="4" class="pa-0">
                    <div class="subtitle-1">
                      <span class="font-weight-bold">Weight: </span>
                      {{ goal?.weight }}
                    </div>
                  </v-col>
                  <v-col cols="12" lg="4" class="pa-0">
                    <div class="subtitle-1">
                      <span class="font-weight-bold">
                        Physical Activity Level:
                      </span>
                      {{ goal?.physicalActivityLevel.description }}
                    </div>
                  </v-col>
                </v-row>
                <v-row justify="center text-left">
                  <v-col cols="12" lg="8" class="pa-0">
                    <div class="subtitle-1">
                      <span class="font-weight-bold">Additional Info: </span>
                      <div v-html="viewPlan?.additionalInfo"/>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider />
            <br/>
            <plans-table
              :viewOnly="true"
              :allPlans="confPlans"
              :roleType="'all'"
              :plan="viewPlan"
              :plans="confPlans"
              :currentFolder="{}"
              ref="planTable"
            />
          </div>
          <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="rejectDialog" max-width="520">
      <v-card>
        <v-card-text class="pa-4">
          <vue-editor
            v-model="reason"
            placeholder="Decline Reason"
            class="mb-3"
          />
          <div class="d-flex align-center gap-5">
            <v-btn
              @click="rejectMealplan(viewPlan)"
              :loading="requesting"
              :disabled="!reason"
              depressed
              small
              class="warning"
              v-if="viewPlan?.requestStatus == 'requesting'"
            >
              Decline request
            </v-btn>
            <v-btn @click="rejectDialog = false" depressed small text>
              Close
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { startCase } from 'lodash';
import { VueEditor } from 'vue2-editor';
import PlansTable from '../meals/components/PlansTable.vue';
import { goals } from '@/options';
export default {
  name: 'MealPlanRequest',
  data() {
    return {
      overlay: false,
      view: 'requesting',
      viewPlan: null,
      viewDialog: false,
      rejectDialog: false,
      requesting: false,
      reason: '',
      goals,
    };
  },
  computed: {
    ...mapState({
      requested: (state) => state.mealplans.requestedPlans,
      rejected: (state) => state.mealplans.rejectedPlans,
      approved: (state) => state.mealplans.approvedPlans,
      status: (state) => state.mealplans.status,
      goal: (state) => state.goal.goal,
    }),
    ...mapGetters('members', ['getUser', 'getRole', 'getEmail']),

    confPlans: function () {
      if (this.view == 'requesting') return this.requested;
      else if (this.view == 'declined') return this.rejected;
      else return this.approved;
    },
  },
  watch: {
    confPlans: function (val) {
      if (val.length) this.getUsers();
    },
  },
  components: {
    PlansTable,
    VueEditor,
  },
  methods: {
    ...mapActions('mealplans', [
      'getReqPlans',
      'getRejPlans',
      'getApprovedPlans',
    ]),
    setView(view) {
      this.view = view;
      !this.$route.query.view || this.$route.query.view !== view
        ? this.$router.push({ query: { view } })
        : '';
    },
    getUserRole(id) {
      const role = this.getRole(id);

      return role === 'personal_trainer'
        ? 'Health & Fitness Professionals'
        : startCase(role);
    },

    getUsers() {
      let ids = [];

      this.confPlans.forEach((plan) => {
        ids.push(plan.user);
      });

      if (ids.length) {
        ids.forEach((id) => {
          this.$store.dispatch('members/getMember', id);
        });
      }
    },

    openViewPlanDialog(plan) {
      this.viewPlan = plan;
      this.viewDialog = true;
      this.$store.dispatch('goal/getGoal', plan.id);
      this.overlay = true;

      setTimeout(() => {
        this.overlay = false;
      }, 4000);
    },

    closeViewPlanDialog() {
      this.viewPlan = null;
      this.viewDialog = false;
      this.reason = '';
    },

    approveMealplan(plan) {
      this.requesting = true;

      Promise.resolve(
        this.$store.dispatch('mealplans/updateMealField', {
          value: 'approved',
          field: 'requestStatus',
          meal: plan,
        })
      ).then(() => {
        this.requesting = false;
        this.viewDialog = false;
        this.$store.dispatch('showSuccess', 'Meal Plan Approved');
        this.$store.commit('mealplans/requestToSuccess', plan);
      });
    },
    rejectMealplan(plan) {
      this.requesting = true;

      Promise.resolve(
        this.$store.dispatch('mealplans/updateMealField', {
          value: 'rejected',
          field: 'requestStatus',
          meal: plan,
        })
      ).then(() => {
        Promise.resolve(
          this.$store.dispatch('mealplans/updateMealField', {
            value: this.reason,
            field: 'rejectReason',
            meal: plan,
          })
        ).then(() => {
          this.requesting = false;
          this.viewDialog = false;
          this.rejectDialog = false;
          this.$store.dispatch('showError', 'Meal Plan was rejected');
          this.$store.commit('mealplans/requestToDeclined', plan);
        });
      });
    },
    getGoalValue(val) {
      return this.goals.find((g) => g.value == val);
    },
  },

  mounted() {
    if (!this.requested.length) this.getReqPlans();
    if (!this.rejected.length) this.getRejPlans();
    if (!this.approved.length) this.getApprovedPlans();
  },
};
</script>

<style></style>
